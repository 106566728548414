<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 100"
    >
        <path
            d="M9.06 79.08h.47c.39 4.79 4.4 8.58 9.29 8.59 4.71 0 8.61-3.52 9.24-8.06h11.77c.63 4.54 4.53 8.05 9.23 8.06 2.49 0 4.84-.97 6.6-2.73a9.282 9.282 0 0 0 2.73-6.6c0-2.49-.97-4.84-2.73-6.6a9.333 9.333 0 0 0-6.6-2.74c-4.9 0-8.92 3.8-9.3 8.61H28.12c-.37-4.81-4.4-8.61-9.3-8.61-2.49 0-4.84.97-6.6 2.73a9.269 9.269 0 0 0-2.64 5.35h-.52c-2.01 0-2.17-1.89-2.17-2.47V60.58c0-1.36 1.24-5.24 6.77-5.24h1.95c.55 0 1-.45 1-1V33.49c-.04-2.27 1.77-4.16 4.02-4.21h22.22c1.94 0 3.62 1.69 4 4.03l4.7 25.74c.09.47.5.82.98.82h9.26V80.5c0 .55.45 1 1 1h21.26c.55 0 1-.45 1-1s-.45-1-1-1H63.79V21.33c0-.55-.45-1-1-1s-1 .45-1 1v36.53h-8.42l-4.55-24.9c-.53-3.3-3.04-5.69-5.97-5.69H20.61c-3.37.07-6.06 2.88-6 6.23v19.84h-.95c-6.05 0-8.77 4.23-8.77 7.24v14.03c0 2.15 1.3 4.47 4.17 4.47Zm40-8.08c1.96 0 3.8.76 5.19 2.15s2.15 3.23 2.15 5.19-.76 3.8-2.15 5.19-3.23 2.15-5.19 2.15c-4.04 0-7.33-3.3-7.33-7.34s3.29-7.33 7.33-7.33Zm-35.42 2.15A7.302 7.302 0 0 1 18.82 71c4.04 0 7.33 3.29 7.33 7.33s-3.29 7.33-7.33 7.33-7.33-3.3-7.33-7.34c0-1.96.76-3.8 2.15-5.19Z"
            class="cls-1"
        />
        <path
            d="M43.15 35.51c-.2-.76-.51-1.91-1.91-1.91H22.59s-.1 0-.15.01c-.93.14-1.59.99-1.51 1.83v22.29c.01.55.46 1 1.01 1l10.64.04 2.35 3.78c.18.29.5.47.85.47h10.28c.55 0 1-.45 1-1v-4.9c0-.06 0-.12-.02-.18l-3.87-21.29s-.03-.13-.04-.15Zm1.92 25.52h-8.73l-2.35-3.78a.995.995 0 0 0-.84-.47l-10.2-.04-.02-21.14H41.1c.04.12.08.28.12.42l3.85 21.2v3.81ZM68.27 50.6v23.67c0 .55.45 1 1 1h24.84c.55 0 1-.45 1-1V50.6c0-.55-.45-1-1-1H69.27c-.55 0-1 .45-1 1Zm2 1h22.84v21.67H70.27V51.6Z"
            class="cls-1"
        />
    </svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
